function clickPurchase(id, partNumber) {
    $('.item_part_number_info').html(partNumber);
    $('.item_name_purchase').html(id);
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
        }
    });
    $.ajax({
        type: "GET",
        url: 'purchase-history/' + id,
        dataType: "text",
        success: function (data) {
            $('.info_purchase').html(data);
        }
    });
}

function showHideInputSearch() {
    var selectedValue = $('#select_search').val();

    $('.key').val(selectedValue);
    $('.text_search').val('');
    if (selectedValue === 'all') {
        $('.search_label_size').hide();
        $('.search_key').hide();
        $('.search_key2').hide();
        $('.search_key3').hide();
    } else if (selectedValue === 'lable_size') {
        $('.search_label_size').show();
        $('.search_key').hide();
        $('.search_key2').hide();
        $('.search_key3').hide();
    } else if (selectedValue === 'dtmDateDelivery' || selectedValue === 'dtmDate') {
        $('.search_label_size').hide();
        $('.search_key').hide();
        $('.search_key2').show();
        $('.search_key3').hide();
    } else if (selectedValue=== 'strStatus') {
        $('.search_label_size').hide();
        $('.search_key').hide();
        $('.search_key2').hide();
        $('.search_key3').show();
    } else {
        $('.search_label_size').hide();
        $('.search_key').show();
        $('.search_key2').hide();
        $('.search_key3').hide();
    }
}
$(document).ready(function () {
    showHideInputSearch();

    $('#select_search').change(function () {
        showHideInputSearch();
    });
});