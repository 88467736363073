$(document).ready(function(){
    $('#meetingForm [name="meeting_date"]').datepicker({
        minDate: 0,
        dateFormat: 'dd M yy',
    });
    $('#meetingForm [name="meeting_date"], #meetingForm [name="meeting_time"]').keydown(function(e){
        e.preventDefault();
    });

    $('#meetingForm').on('submit', function(e){
        e.preventDefault();
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
            }
        });

        $.ajax({
            type: "POST",
            url: 'appointment',
            data: {
                date: $('#meetingForm [name="meeting_date"]').val(),
                time: $('#meetingForm [name="meeting_time"]').val(),
                strDescription: $('#meetingForm [name="remark"]').val()
            },
            dataType: 'json',
            success: function(msg) {
                setTimeout(function () {
                    $('#modal_request_meeting_success').modal('show');
                }, 500);
                $('#modal_request_meeting').modal('hide');
                $('.error_modal_request_metting').hide();
            },
            error: function(XMLHttpRequest, textStatus, errorThrown) {
                $('.error_modal_request_metting').show().html(XMLHttpRequest.responseJSON.message)
            }

        });
    });
});
