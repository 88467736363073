$(document).ready(function () {
    $(window).resize(function(e){
        var w = $(window).width();
        $('.nav-mobile').removeClass('action');
        if(w > 992){
            $('.pc-nav').css('display','block');
        }else{
            $('.pc-nav').css('display','none');
        }
        
    });

    $(window).resize(function(e){
        var w = $(window).width();
        $('.nav-mobile').removeClass('action');
        if(w > 992){
            $('.pc-nav').css('display','block');
        }else{
            $('.pc-nav').css('display','none');
        }
    });

    $('.nav-mobile').click(function(e){
        e.preventDefault();
        $(this).toggleClass('action');
        $('.pc-nav').slideToggle();
    });
});