$(document).ready(function () {
    /**
     * Back to top
     */
    $(window).scroll(function () {
        if ($(window).scrollTop() > 92) {
            $('#back-to-top').fadeIn();
        } else {
            $('#back-to-top').fadeOut();
        }

        if ($(window).scrollTop() > 120) {
            $('.right-cart-sidebar').addClass('sticky-sidebar');
        } else {
            $('.right-cart-sidebar').removeClass('sticky-sidebar');
        }
    });
    $('#back-to-top').click(function() {
        $('html, body').animate({scrollTop:0},1000);
    });

    /**
     * Search
     */
    $(".icon_search_menu").click(function(){
        $(".nav_search_form").toggle();
    });

    /**
     * Time picker
     */
    $('input.timepicker').timepicker({});
    $("#datepicker").datepicker({
        minDate: 0
    });

    /* Where is this element?  */
    $('.slide-3').slick({
        dots:false,
        infinite: true,
        speed: 600,
        autoplay: true,
        arrows:true,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: false,
        responsive: [

            {
                breakpoint: 560,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 420,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });
});