
function addCommas(nStr) {
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}

function viewSkuQtyExistPart(cntID) {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
        }
    });
    $.ajax({
        type: "GET",
        url: 'sku-view-qty-list/'+cntID,
        dataType: "text",
        success: function(data)
        {
            $('.info_view_sku_qty').html(data);

        }
    }).done(function() {
        var makeEqualHeight = setInterval(function() {
            $('.single-select-sku-qty').matchHeight();
        }, 500);
        setTimeout(function() {
            clearInterval(makeEqualHeight);
        }, 3000);
    });
}

function updateTextQtyColor() {
    setTimeout(function(){
        var maxSku = parseInt($('.total-sku-required-color').text());
        var enteredSku = parseInt($('.total-sku-entered-color').text());

        if (enteredSku < 1 || enteredSku > maxSku) {
            $('.total-sku-entered-color').css('color', 'red');
            $('.total-sku-entered-top-color').css('color', 'red');
        } else {
            $('.total-sku-entered-color').css('color', 'black');
            $('.total-sku-entered-top-color').css('color', 'black');
        }

        if ($('.total-qty-required-color').text() != $('.total-qty-entered-color').text()) {
            $('.total-qty-entered-color').css('color', 'red');
            $('.total-qty-entered-top-color').css('color', 'red');
        } else {
            $('.total-qty-entered-color').css('color', 'black');
            $('.total-qty-entered-top-color').css('color', 'black');
        }

        if ($('.total-price-required-color').text() != $('.total-price-entered-color').text()) {
            $('.total-price-entered-color').css('color', 'red');
            $('.total-price-sign-entered-color').css('color', 'red');
            $('.total-price-entered-top-color').css('color', 'red');
        } else {
            $('.total-price-entered-color').css('color', 'black');
            $('.total-price-sign-entered-color').css('color', 'black');
            $('.total-price-entered-top-color').css('color', 'black');
        }
    });
}


$(document).ready(function () {
    $('.datepicker').datepicker({
        dateFormat: 'dd/mm/yy',
        changeMonth: true,
        changeYear: true,
    });

    $(document).on('click','body .btn_confirm_edit_total',function(){
        var name = $('.list_chose_active').find(".quantity").attr('data-id');
        var quantity = $('.list_chose_active').find(".quantity").attr('data-quantity');
        var id = $(this).attr('data-id');
        $('#myModal-total').modal('hide');
        setTimeout(function(){
            $('.total-sku-qty-select').html(name);
            $('[data-double-quantity="' + id + '"]').html(quantity);
            $('#modal_edit_sku_qty').modal('show');
        },1000);
    });

    $(document).on('click','body #myModal-total .close_quantity_partprice_edit',function(){
        var name = $('.list_chose_active').find(".quantity").attr('data-id');
        var quantity = $('.list_chose_active').find(".quantity").attr('data-quantity');
        var id = $('.btn_confirm_edit_total').attr('data-id');
        $('#myModal-total').modal('hide');
        setTimeout(function(){
            $('.total-sku-qty-select').html(name);
            $('[data-double-quantity="' + id + '"]').html(quantity);
            $('#modal_edit_sku_qty').modal('show');
        },1000);

    });

    $('#modal_select_sku_qty, #modal_edit_sku_qty').on('keyup', '.table-enter-sku-qty input',function(){
        var totalInputedCount = 0;
        var totalInputed = 0;
        var totalPriceEntered = 0;
        $('.single-select-sku-qty').each(function(){
            if ($(this).find('input').val() > 0) {
                totalInputedCount++;
                totalInputed = +totalInputed + +($(this).find('input').val());
            }
        });
        if ($('#price-selection').data('price') != "0") {
            totalPriceEntered = totalInputed * $('#price-selection').data('price');
        }

        if(totalPriceEntered != 0) {
            totalPriceEntered = parseFloat(totalPriceEntered).toFixed(2);
        }
        $('.total-sku-entered').text(totalInputedCount);
        $('.total-qty-entered').text(addCommas(totalInputed));
        $('.total-price-entered').text(addCommas(totalPriceEntered));

        updateTextQtyColor();
    });


    $('#modal_select_sku_qty, #modal_edit_sku_qty').on('change', '#quantity-selection', function(){
        var currentValue = $(this).val();
        var currentText = $("#quantity-selection option:selected").text();
        var totalInputed = 0;
        var totalPriceEntered = 0;
        var totalPriceRequired = 0;
        $('.single-select-sku-qty').each(function(){
            if ($(this).find('input').val() > 0) {
                totalInputed = +totalInputed + +($(this).find('input').val());
            }
        });
        if(currentValue != "") {
            var qtyPrice = currentText.split(" - $");
            var price = qtyPrice[1];
            $('#price-selection').data('price', price);
            totalPriceEntered = totalInputed * price;
            totalPriceRequired = currentValue * price;

            if(totalPriceRequired != 0) {
                totalPriceRequired = parseFloat(totalPriceRequired).toFixed(2);
            }

            $('.total-qty-required').text(addCommas(currentValue));

            $('.total-price-required').text(addCommas(totalPriceRequired))
        } else {
            $('.total-qty-required').text("0");
            $('.total-price-required').text("0");
        }
        if(totalPriceEntered != 0) {
            totalPriceEntered = parseFloat(totalPriceEntered).toFixed(2);
        }

        $('.total-price-entered').text(totalPriceEntered);
        updateTextQtyColor();

    });

    $('#modal_select_sku_qty, #modal_edit_sku_qty').on('change', '#sku-selection', function(){
        var currentValue = $(this).val();
        var quantitySelection = $('#quantity-selection');
        var strPartCode = $('#existpart-partcode').data('partcode');
        if (currentValue !== "") {
            $.ajax({
                url: "sku-get-qty-dropdown",
                type: 'GET',
                data: {
                    intSKU: currentValue,
                    strPartCode: strPartCode
                },
                success: function (result, textStatus, xhr) {
                    if (result.status === 'success') {
                        quantitySelection.find('option[value!=""]').remove();
                        var dataDropdown = result.data;
                        for (var item in dataDropdown) {
                            quantitySelection.append(
                                '<option value="' + item + '">' + dataDropdown[item] + '</option>'
                            );
                        }
                    }
                    $('.total-price-entered').text("0");
                    $('.total-qty-required').text("0");
                    $('.total-price-required').text("0");

                    var totalInputtedCount = 0;
                    $('.single-select-sku-qty').each(function(){
                        if ($(this).find('input').val() > 0) {
                            totalInputtedCount++;
                        }
                    });
                    $('.total-sku-entered').text(totalInputtedCount);
                    $('.total-sku-required').text(currentValue);

                },
                error: function (xhr, textStatus, errorThrown) {
                }
            });
        } else {
            quantitySelection.find('option[value!=""]').remove();
            $('.total-price-entered').text("0");
            $('.total-price-required').text("0");
            $('.total-sku-required').text("0");
            $('.total-qty-required').text("0");
        }
        updateTextQtyColor();
    });
});

function editSkuQtyExistPart(cart_id, id, link_id) {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
        }
    });
    $.ajax({
        type: "GET",
        url: 'sku-edit-qty-list/'+cart_id+'/'+id+'/'+link_id,
        dataType: "text",
        success: function(data)
        {
            $('.info_edit_sku_qty').html(data);
        }
    }).done(function() {
        var makeEqualHeight = setInterval(function() {
            $('.single-select-sku-qty').matchHeight();
        }, 500);
        setTimeout(function() {
            clearInterval(makeEqualHeight);
        }, 3000);

        var totalInputtedCount = 0;
        $('.single-select-sku-qty').each(function(){
            if ($(this).find('input').val() > 0) {
                totalInputtedCount++;
            }
        });
        $('.total-sku-entered').text(totalInputtedCount);
    });

}

function clickPartPriceEdit(id) {
    $('#modal_edit_sku_qty').modal('hide');
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
        }
    });
    $.ajax({
        type: "GET",
        url: 'part-price-edit/'+id,
        dataType: "text",
        success: function(data)
        {
            setTimeout(function(){
                $('#myModal-total').modal("show");
                if (!$('body').hasClass('modal-open')) {
                    $('body').addClass('modal-open');
                }
                $('.info_partPrice_edit').html(data);
            });
        }
    });

}

function updateCartEditSkuQty(id, link_id) {
    var totalInputtedCount = 0;
    var totalInputted = 0;
    var inputtedQty = {};
    var currentText = $("#quantity-selection option:selected").text();

    var dblQty = $('#quantity-selection').val();
    var strUOM = $('[data-str-uom="' + id + '"]').text();
    var intSkuQty = $('#sku-selection').val();

    if (intSkuQty == "") {
        $('.error_modal_text.error_modal_select_qty').html('Please fill up mandatory fields*');
        $('.error_modal_text.error_modal_select_qty').show();
        return false;
    }
    if (dblQty == "") {
        $('.error_modal_text.error_modal_select_qty').html('Please fill up mandatory fields*');
        $('.error_modal_text.error_modal_select_qty').show();
        return false;
    }

    $('.single-select-sku-qty').each(function(){
        if ($(this).find('input').val() > 0) {
            totalInputtedCount++;
            totalInputted = +totalInputted + +($(this).find('input').val());
            var cntID = $(this).find('input').data('id');
            inputtedQty[cntID] = +($(this).find('input').val());
        }
    });

    if (totalInputtedCount < 1) {
        $('.error_modal_text.error_modal_select_qty').html('Please enter quantity for at least 1 SKU');
        $('.error_modal_text.error_modal_select_qty').show();
        return false;
    }

    if (totalInputtedCount > intSkuQty) {
        $('.error_modal_text.error_modal_select_qty').html('Please enter quantity for maximum of ' + intSkuQty + ' SKU');
        $('.error_modal_text.error_modal_select_qty').show();
        return false;
    }

    if (totalInputted != dblQty) {
        $('.error_modal_text.error_modal_select_qty').html('Please fulfill a total of ' + addCommas(dblQty) + 'pcs in quantity. ' +
            'Your current input is ' + addCommas(totalInputted) + 'pcs');
        $('.error_modal_text.error_modal_select_qty').show();
        return false;
    }

    var qtyPrice = currentText.split(" - $");
    var curSalesPrice = qtyPrice[1];

    if (totalInputtedCount >= 1 &&  totalInputtedCount <= intSkuQty && totalInputted == dblQty) {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
            }
        });

        $.ajax({
            type: "POST",
            url: 'updateCartExistPartSku',

            data: {
                "inputed_value": inputtedQty,
                "partID": id,
                "dblQty": dblQty,
                "curSalesPrice": curSalesPrice,
                "strUOM": strUOM,
                "link_id": link_id,
                "intSKU": intSkuQty
            },
            dataType: "text",
            success: function (data) {
                if (data === 'error') {
                    setTimeout(function () {
                        $('.error_modal_text.error_modal_select_qty').html('Sorry, the item is already in the cart.');
                        $('.error_modal_text.error_modal_select_qty').show();
                        return false;
                    }, 500);
                } else {
                    $('.error_modal_text.error_modal_select_qty').hide();
                    $('.success_modal.success_modal_select_qty').show();
                    $('.success_modal.success_modal_select_qty').html('Cart updated successfully');

                    $('#' + id).html(dblQty + ' ' + strUOM + ' ($' + curSalesPrice + ')');
                    $('#intsku-' + id).html(intSkuQty);

                    // Update cart total price
                    $.ajax({
                        type: "GET",
                        url: 'getTotalCartAmount',
                        dataType: "text",
                        success: function (data) {
                            $('.total_right_money').html(data);

                        }
                    });

                    setTimeout(function () {
                        $('.close_sku_quantity').trigger('click');
                    }, 1500);
                }
            }
        });
    }
}
